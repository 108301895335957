@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl font-extrabold;
  }
  h2 {
    @apply text-3xl font-semibold;
  }
  h3 {
    @apply text-2xl font-semibold;
  }
  h4 {
    @apply text-xl font-semibold;
  }
}

.ql-align-center {
  text-align: center;
}